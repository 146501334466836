const paymentutils = {
    /** Begin other payment amount validation* */
    validateOtherPaymentAmount() {
        const isOtherPayment = $('#dwfrm_accountpayment_amountChoice_other').is(':checked');
        // If other payment is not selected return true to allow forms submission so no validation is needed
        if (!isOtherPayment) {
            return true;
        }
        const pendingPayments = parseFloat($('.payment-row.other-payment label').first().data('pendingpayment')).toFixed('2');
        const accountBalance = parseFloat($('.payment-row.other-payment label').first().data('currentbalance')).toFixed('2');
        // If the other payment amount is less than or equal to the account balance plus any pending payments return true
        const isValidPayment = $('#dwfrm_accountpayment_otherAmount').val() <= (accountBalance - pendingPayments);
        if (isValidPayment) {
            return true;
        }
        return false;
    },
    /** End other payment amount validation* */

    /** Start bank routing number validation* */
    validateRoutingNumber() {
        // If any of the possible bank account radio buttons are checked we are utilizing at least one set of ACH fields
        const isBankPaymentMethod = $('#dwfrm_accountpayment_paymentMethods_selectedPaymentMethodID_ACH').is(':checked') || $('#dwfrm_accountautopay_accountpayment_paymentMethods_selectedPaymentMethodID_ACH').is(':checked') || $('#is-ACH').is(':checked');
        // If a bank account is not in use return true indicating validation is not needed and to proceed
        if (isBankPaymentMethod) {
            // Routing number fields should have class .routing-field added to the field to apply routing number validation
            const routingNumberField = $('.routing-field');
            const routingNumberResults = {};
            // Loop through routing number fields
            for (let i = 0; i < routingNumberField.length; i += 1) {
                // Check the current routing number field if it is visible the user is utilizing it
                const isFieldInUse = $(routingNumberField[i]).is(':visible');
                if (isFieldInUse) {
                    // Build results object out using field id + the results of passing the routing number into the validation helper
                    const routingFieldName = $(routingNumberField[i]).find('input').attr('id');
                    const routingFieldNumber = $(routingNumberField[i]).find('input').val();
                    routingNumberResults[routingFieldName] = this.checkRoutingNumber(routingFieldNumber);
                }
            }
            return routingNumberResults;
        }
        return true;
    },

    checkRoutingNumber(routingNumber) {
        // Verify length routing numbers need to be exactly 9 digits
        if (routingNumber.length === 9) {
            let n = 0;
            let i;
            // Standard formula for routing number validation
            for (i = 0; i < routingNumber.length; i += 3) {
                n += parseInt(routingNumber.charAt(i), 10) * 3
                + parseInt(routingNumber.charAt(i + 1), 10) * 7
                + parseInt(routingNumber.charAt(i + 2), 10);
            }
            // If the resulting sum is an even multiple of ten (but not zero), the routing number is good.
            if (n !== 0 && n % 10 === 0) {
                return true;
            }
            return false;
        }
        return false;
    },
    /** End bank routing number validation* */
};

module.exports = paymentutils;
