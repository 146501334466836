const ajax = require('./ajax');
const minicart = require('./minicart');
const util = require('./util');

const setAddToCartHandler = (event) => {
    event.preventDefault();
    const form = $(event.currentTarget).closest('form');

    const options = {
        url: util.ajaxUrl(form.attr('action')),
        method: 'POST',
        cache: false,
        data: form.serialize(),
    };
    $.ajax(options).done((data) => {
        if (data.success) {
            ajax.load({
                url: Urls.minicartGC,
                data: {lineItemId: data.result.lineItemId},
                callback(response) {
                    minicart.show(response);
                    form.find('input,textarea').val('');
                },
            });
        } else {
            form.find('span.error').hide();
            Object.keys(data.errors.FormErrors).forEach((id) => {
                let $errorEl = $(`#${id}`).addClass('error').removeClass('valid').next('.error');
                if (!$errorEl || $errorEl.length === 0) {
                    $errorEl = $(`<span for="${id}" generated="true" class="error" style=""></span>`);
                    $(`#${id}`).after($errorEl);
                }
                $errorEl.text(data.errors.FormErrors[id].replace(/\\'/g, '\'')).show();
            });
        }
    }).fail((xhr, textStatus) => {
        if (textStatus === 'parsererror') {
            window.alert(Resources.BAD_RESPONSE);
        } else {
            window.alert(Resources.SERVER_CONNECTION_ERROR);
        }
    });
};

exports.init = () => {
    $('#AddToBasketButton').on('click', setAddToCartHandler);
};
